.pdfBody table, .pdfBody tr, .pdfBody th, .pdfBody td {
    border: 1px solid black;
}

.pdfBody th {
    font-size: 14px;
    padding: 0px 5px;
}

.pdfBody td {
    text-align: center;
}

.pdfBody tr {
    padding: 0px 5px;
    line-height: 20px;
}

.pdfBody table {
    width: 800px;
    margin-bottom: 100px;
    margin-left: 50px;
}

.pdfBody {
    background: white;
    width: 1000px;
    margin-top: 1000px;
}

.pdfBody .tableSection {
    background:	#bef4d9;
}

.pdfBody h4 {
    text-align: center;
    padding-top: 50px;
}

.pdfBody .contactInfo {
    margin-left: 50px;
    margin-bottom: 25px;
}

.pdfBody .contactInfo p {
    margin: 0px;
}

.pdfBody .signature {
    text-align: center;
}

.pdfBody .signatureImg {
    width: 200px;
}

.pdfBody p {
    text-align: left;
}
