.Container {
  max-width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 10vh;
  background-color: #eeeeee;
  border: #c4c4c4 1px dashed;
}
.FileInput {
  width: 100% !important;
  height: 100px;
}
.ImageInput {
  text-align: center;
  height: 10vh !important;
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.UploadBtn {
  line-height: 2;
  height: 10vh;
  width: 100%;
  padding: 3px 12px !important;
  border: none;
  border-radius: 5px;
  background-color: #eeeeee;
}
.UploadForm {
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.UploadWrapper,
.CommentWrapper {
  flex: 1 1 0;
}
.CommentWrapper {
  margin-left: 10%;
}
.Textarea {
  height: 160px;
  width: 90%;
  resize: none;
  border-radius: 2px;
}
@media screen and (max-width: 1055px) {
  .UploadForm {
    flex-direction: column;
  }
  .Container {
    max-width: 100%;
  }
  .CommentWrapper {
    margin-left: 0;
  }
  .Textarea {
    width: 100%;
  }
}
