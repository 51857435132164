.Collapse {
  margin-bottom: 3%;
}
.CollapseBtn {
  width: 100%;
  background-color: #fafafa;
  border: 1px solid lightgray;
  color: black;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0 !important;
  margin-top: 1%;
}

.CollapseBtn:hover {
  background-color: #fafafa;
  border: 1px solid lightgray;
  color: black;
  transition: none;
}
.CollapseBtn p {
  margin: 0;
  font-weight: 300;
}
.popup-content {
  width: 80% !important;
}
.SignatureCanvas {
  border: 1px solid black;
  width: 100%;
  height: 72vh !important;
}
.SignatureBtn {
  line-height: 2;
  min-height: 2rem;
  padding: 3px 12px !important;
  border: 1px solid black;
  border-radius: 5px;
  background-color: #eeeeee;
}
.SignatureForm {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.ContentWrapper {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}
.DateOne,
.DateTwo {
  margin-top: 5%;
}
.Required::after {
  content: " *";
  color: darkred;
}
@media screen and (max-width: 1055px) {
  .SignatureForm {
    flex-direction: column;
  }
}
