.Collapse {
  margin-bottom: 3%;
}
.CollapseBtn {
  width: 100%;
  background-color: #fafafa;
  border: 1px solid lightgray;
  color: black;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0 !important;
  margin-top: 1%;
}

.CollapseBtn:hover {
  background-color: #fafafa;
  border: 1px solid lightgray;
  color: black;
  transition: none;
}
.CollapseBtn p {
  margin: 0;
  font-weight: 300;
}
.CheckboxWrapper {
  display: flex;
  flex-direction: column;
  width: 33%;
}
.CheckboxWrapper h5::after {
  content: " *";
  color: darkred;
}
.BedroomTwoFormWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
@media screen and (max-width: 978px) {
  .CheckboxWrapper {
    width: 50%;
  }
}
