html, body, #root, .root, #app, #app>div {
  height: 100vh;
}

.FormHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.ContactForm {
  margin-top: 1vh;
}
.ContactForm h1 {
  text-align: center;
}
.Loader {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 99;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
}
.ErrorMessage {
  color: red;
}
.SuccessContainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.SubmitBox {
  display: flex;
  flex-direction: column;
  margin: 3% 0% 5% 0%;
  justify-content: center;
  align-items: center;
}
.SubmitButtonContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;
}
/* .SubmitBtn:hover {
  background-color: #5e5e5e;
  color: white;
  cursor: pointer;
} */
.ModalText {
  color: white;
  padding: 20px;
  text-align: center;
}
.ModalWrapper {
  background-color: #7c7c7c;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.FiveStarImage {
  max-width: 50%;
}
.FiveStarImageWrapper {
  display: flex;
  justify-content: center;
}
.ContactForm {
  max-width: 80vw;
  margin: 0 auto;
  font: 1em/1.65em "Open Sans", "Arial", sans-serif;
}
.ReviewBtn {
  font-size: 24px;
  background-color: #1d435c;
}
.SubmitBtn {
  background: white !important;
  color: black !important;
}
@media screen and (max-width: 978px) {
  .ContactForm {
    max-width: 95vw;
  }
}
@media screen and (max-width: 750px) {
  .SubmitBtn {
    width: 100%;
    margin-bottom: 50px;
    background: white !important;
    color: black !important;
  }
  .SubmitBox {
    flex-direction: column;
  }
  .FormHeader {
    flex-direction: column;
  }
}
