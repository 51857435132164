.Collapse {
  margin-bottom: 3%;
}
.CollapseBtn {
  width: 100%;
  background-color: #fafafa;
  border: 1px solid lightgray;
  color: black;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0 !important;
  margin-top: 1%;
}

.CollapseBtn:hover {
  background-color: #fafafa;
  border: 1px solid lightgray;
  color: black;
  transition: none;
}
.CollapseBtn p {
  margin: 0;
  font-weight: 300;
}
.Question {
  margin-bottom: 0;
  margin-top: 10px;
  font-weight: 600;
}
.BelowThreeRating {
  width: 100%;
  height: 160px;
  resize: none;
  border-radius: 2px;
  margin-top: 1%;
}
.ImproveArea {
  margin-top: 3%;
}
.Question::after {
  content: " *";
  color: darkred;
}
