.Collapse {
    margin-bottom: 3%;
}

.CollapseBtn {
    width: 100%;
    background-color: #fafafa;
    border: 1px solid lightgray;
    color: black;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0 !important;
    margin-top: 1%;
}

.CollapseBtn:hover {
    background-color: #fafafa;
    border: 1px solid lightgray;
    color: black;
    transition: none;
}

.CollapseBtn p {
    margin: 0;
    font-weight: 300;
}

.termsText {
    margin-bottom: 25px;
}

.termsCheckboxLabel {
    display: inline-block;
    margin-left: 15px;
    font-size: 18px;
}